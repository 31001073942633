<script lang="ts">
  import { twMerge } from 'tailwind-merge';
  import type {
    HTMLButtonAttributes,
    HTMLAnchorAttributes,
    HTMLAttributeAnchorTarget,
  } from 'svelte/elements';
  import Icon from '$lib/components/icon/icon.svelte';
  import type { IconName } from '$lib/components/icon/icons';
  import type { ButtonVariant } from '$lib/contentful/models/call-to-action';
  import type { Theme } from '$lib/contentful/models/theme';

  type BaseProps = {
    class?: string;
    variant?: ButtonVariant;
    leadingIcon?: IconName | null;
    trailingIcon?: IconName | null;
    large?: boolean;
    align?: 'left' | 'center';
    loading?: boolean;
    theme?: Theme;
  };

  type ButtonProps = BaseProps & HTMLButtonAttributes;
  type AnchorProps = BaseProps & HTMLAnchorAttributes;

  type $$Props = ButtonProps | AnchorProps;

  let className = '';
  export { className as class };
  export let leadingIcon: IconName | null | undefined = undefined;
  export let trailingIcon: IconName | null | undefined = undefined;
  export let href: string | null | undefined = '';
  export let variant: ButtonVariant = 'primary';
  export let large = false;
  export let align = 'center';
  export let disabled: null | boolean = false;
  export let loading = false;
  export let theme: Theme = 'ultraviolet';

  const iconClasses: Record<ButtonVariant, Record<Theme, string>> = {
    primary: {
      ultraviolet: 'group-hover:text-indigo-100',
      green: '',
      pink: 'group-hover:text-pink-100',
      'off-white': 'text-off-white group-hover:text-blue-gray-300',
    },
    secondary: {
      ultraviolet: 'text-indigo-300 group-hover:text-off-white',
      green: 'text-green-300 group-hover:text-off-white',
      pink: 'text-pink-200 group-hover:text-off-white',
      'off-white': 'text-off-white group-hover:text-blue-gray-300',
    },
    ghost: {
      ultraviolet: 'text-ultraviolet group-hover:text-indigo-300',
      green: 'group-hover:text-green-200',
      pink: 'text-pink-200 group-hover:text-pink-100',
      'off-white': 'text-off-white group-hover:text-blue-gray-300',
    },
  };

  const targetForHref = (
    href: string | null | undefined,
  ): HTMLAttributeAnchorTarget | null => {
    if (href) {
      if (href.startsWith('/') || href.startsWith('#')) return '_self';
      if (href.startsWith('http')) return '_blank';
    }

    return null;
  };
</script>

<svelte:element
  this={href ? 'a' : 'button'}
  role={href ? 'link' : 'button'}
  target={targetForHref(href)}
  {disabled}
  {href}
  on:click
  class={twMerge('button', 'group', 'w-fit', variant, align, theme, className)}
  class:large
  class:disabled
  {...$$restProps}
>
  {#if loading}
    <Icon class="animate-spin" name="spinner" />
  {:else if leadingIcon}
    <Icon
      class="transition-colors {iconClasses[variant][theme]}"
      name={leadingIcon}
    />
  {/if}
  <slot />
  {#if trailingIcon}
    <Icon
      class="transition-colors {iconClasses[variant][theme]}"
      name={trailingIcon}
    />
  {/if}
</svelte:element>

<style lang="postcss">
  .button {
    @apply h-14 box-border flex gap-4 no-underline cursor-pointer appearance-none max-sm:text-sm sm:text-base font-medium font-mono rounded-lg transition-colors disabled:pointer-events-none;
  }

  .primary {
    @apply hover:ring-4 active:ring-0 transition-shadow;

    &.off-white {
      @apply bg-space-black disabled:bg-opacity-70 hover:ring-blue-gray-200/70;
    }

    &.ultraviolet {
      @apply bg-gradient-ultraviolet disabled:bg-gradient-ultraviolet-disabled text-off-white hover:ring-ultraviolet/70 active:text-indigo-100 disabled:text-opacity-40;
    }

    &.green {
      @apply bg-gradient-green disabled:bg-gradient-green-disabled text-space-black hover:ring-green-300/70 active:bg-gradient-green-active disabled:text-opacity-40;
    }

    &.pink {
      @apply bg-gradient-pink disabled:bg-gradient-pink-disabled text-off-white hover:ring-pink-200/70 active:text-pink-100 disabled:text-opacity-40;
    }
  }

  .secondary {
    @apply text-white bg-space-black border-2 border-transparent disabled:text-opacity-75;

    &.off-white {
      @apply border-off-white hover:border-blue-gray-300;
    }

    &.ultraviolet {
      @apply border-gradient-ultraviolet hover:border-indigo-300;
    }

    &.green {
      @apply border-gradient-green hover:border-green-800;
    }

    &.pink {
      @apply border-gradient-pink hover:border-pink-600;
    }
  }

  .ghost {
    @apply bg-transparent disabled:text-white/70;

    &.off-white {
      @apply text-off-white hover:text-blue-gray-300;
    }

    &.ultraviolet {
      @apply text-off-white hover:text-indigo-100;
    }

    &.green {
      @apply text-green-300 hover:text-off-white;
    }

    &.pink {
      @apply text-pink-100 hover:text-off-white;
    }
  }

  .left {
    @apply items-start pr-6;
  }

  .center {
    @apply items-center justify-center px-6;
  }

  .large {
    @apply rounded-2xl h-20 px-16;
  }
</style>
